import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container, ListGroup, Carousel } from "react-bootstrap"

const SubmitPage = ({data}) => (
  <Layout pageInfo={{ pageName: "submit" }} active="">
    <SEO title="Submit" keywords={[`unhistoried`, `submit`, `brrrlabs`]} />
    <Container className="submit-page">
      <Row noGutters className="unhistoried-submit-row">
        <Col md={4} className="unhistoried-submit-col-img">
          <GatsbyImage image={data.contentfulSubmit.submitFeaturedImage.gatsbyImageData}  />
        </Col>
        <Col md={8} className="unhistoried-submit-col">
          <div dangerouslySetInnerHTML={{
            __html: data.contentfulSubmit.submitParagraph.childMarkdownRemark.html.replace(/href/g, "target='_blank' href")
          }}></div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export const submitQuery = graphql`
query SubmitQuery {
  contentfulSubmit(contentful_id: {eq: "7Izyfepi44CJdvAl5FBvWh"}) {
    submitParagraph {
      childMarkdownRemark {
        html
      }
    }
    submitFeaturedImage {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
    }
    contentful_id
  }
}`

export default SubmitPage
